.account {width: 100%;

  .module {@include padbox(1em);
    @include desk {padding: 1em 0;}
  }

  &--user {width: 100%; background-color: $soft; margin-bottom: 1em;
    header {width: 100%; @include padbox(1.5em 1em); margin-bottom: 1em; @include module;
      @include desk {padding: 1.5em 0;}
      p {font-size: 0.85em; font-weight: 500; margin-bottom: 0.5em;}
      a {font-size: 1.25em; font-weight: 300;
        &:hover {color: $blue;}
      }
    }
  }

  &--dashboard {width: 100%; @include padbox(1em);
    @include desk {padding: 1em 0;}
    section {width: 100%; @include module;
      > header {width: 100%; @include padbox(0 1em 1em 1em);
        h1 {font-size: 2em; font-weight: 300;}
        @include desk {padding: 0 0 1em 0;}
      }
      @include flexbox; @include flow-rw; @include just-between; @include items-stretch;
      @include desk {padding: 0;}
      a {width: 100%; @include padbox(1.35em); position: relative; border: 1px solid $light;
        margin-bottom: 1em; box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
        // @include flexbox; @include flow-rw; @include just-start; @include items-center;
        @include tablet {width: 49%;}
        i {height: 40px; float: left; padding-right: 1em;
          svg {height: 100%; width: auto; max-width: 50px; display: inline-block;}
        }
        h2 {font-size: 1.25em; font-weight: 300; padding-top: 0.5em;
          @include tablet {padding-top: 0;}
        }
        p {color: $grey; display: block; width: 100%; padding-top: 0.5em; clear: both;
          @include tablet {display: inline-block; width: auto; clear: none; padding-top: 0;}
        }
        &::after {content: "\f105"; font-family: $icon; color: $blue; display: inline-block;
          position: absolute; top: 42%; right: 1em;
        }
        &:hover {border-color: $blue;
          // box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6), 0px 0px 2px $blue inset;
        }
      }
    }
  }

  &--header {width: 100%; margin-bottom: 2em; position: relative;
    @include flexbox; @include flow-rw; @include just-start; @include items-center;
    i {width: 40px; margin-right: 0.5em;
      svg {width: 100%; display: inline-block;}
    }
    h1 {font-size: 1.75em; font-weight: 300; display: inline-block;}
  }

  &--content {width: 100%; @include flexbox; @include flow-rw; @include just-between; @include items-stretch;
    .grid {width: 100%;
      @include tablet {width: 48.5%;}
      @include desk {width: 23.5%;}
    }
  }

  &--box {width: 100%; margin-bottom: 1em; @include padblock(1em); @include trans;
    border: 1px solid $light; box-shadow: 0px 2px 5px #ACAEAE;
    @include tablet {width: 48.5%;}
    @include desk {width: 23.5%;}
    label {width: 100%; margin-bottom: 0.5em;
      // @include flexbox; @include flow-rw; @include just-start; @include items-center;
      // span {font-size: 1em;}
      // i {@include padblock(3px); @include size(17px, 17px); @include radius(99px); position: relative;
      //   border: 1px solid $light; background-color: white; margin-right: 0.5em; text-align: center;
      //   &::before {content: ""; display: block; @include size(9px, 9px); @include radius(99px); @include trans;
      //     background-color: white;
      //   }
      // }
      // input {display: none;
      //   &:checked + div::before {background-color: $blue;}
      // }
    }
    h5 {font-size: 1em; font-weight: 400;}
    h6 {font-size: 0.85em; font-weight: 400; margin: 0.5em 0;}
    p {color: $grey;}
    footer {width: 100%; margin-top: 0.5em;
      @include flexbox; @include flow-rw; @include just-between; @include items-center;
      div {
        button {@include padbox(0.5em 1em 0.5em 0); color: $blue; font-size: 0.85em;
          &.add { font-size: 1rem; }
          &:hover {text-decoration: underline;}
        }
      }
      i {
        svg {height: 1.5em; width: auto; display: inline-block;}
      }
    }
    &:hover {border-color: $blue;
      // box-shadow: 0px 2px 5px #ACAEAE, 0px 0px 2px $blue inset;
    }
  }

  &--button {width: 100%; text-align: center; margin: 2em 0;
    a, button {@include padbox(0.5em); min-width: 180px; color: $blue; background-color: white; border: 1px solid $blue; @include radius(3px);
      &:hover {background-color: $blue; color: White;}
    }
  }

  &--form {width: 100%; @include padbox(1em); @include module;
    form {width: 100%; @include flexbox; @include flow-rw; @include just-between; @include items-center;
      @include desk {width: 62%; padding: 0;}
      > div {width: 100%; margin-bottom: 1em;
        p {font-size: 0.9em; font-weight: 400; padding-bottom: 5px;}
        label {width: 100%; position: relative;
          &.select {
            margin-bottom: 0.5rem;
            @include tablet { width: 33%; }
            &.full {
              @include tablet { width: 100%; }
            }
          }
        }
        @include tablet {
          &.half {width: 48%;}
          &.third {width: 22%;}
        }
      }
      footer {width: 100%; padding: 1.5em 0 2em 0;
        @include flexbox; @include flow-rw; @include just-between; @include items-center;
        a, button {@include padbox(0.5em); width: 100%; border: 1px solid; @include radius(3px); text-align: center;
          @include tablet {width: 48%;}
        }
        a {color: $blue; border-color: $blue; background-color: white; margin-bottom: 0.5em;
          @include tablet {margin-bottom: 0;}
        }
        button {color: white; border-color: $blue; background-color: $blue;}
      }
    }
  }

  &--info {
    width: 100%;
    @include tablet { width: 50%; }
    &-address, &-profile {
      p { font-size: 1rem; padding: 0.5rem; }
      div { padding: 0.5rem;
        a { margin-right: 1rem; min-width: 8rem; @include radius(6px); text-align: center; font-size: 0.9rem; border: 1px solid $blue;
          &:first-of-type { @include padbox(0.5rem); color: $blue; background-color: white; }
          &:last-of-type { @include padbox(0.5rem); background-color: $blue; color: white; }
        }
      }
    }
  }

}
