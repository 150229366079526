.cart {@include module;

  &--header {width: 100%; @include padbox(1em);
    h1 {font-size: 2em; 
      color:#2999A9;
      font-weight: 600;}
    @include desk {padding: 1em 0;}
  }
  &--header-detail{
    margin-bottom: 1em;
    @include phone { 
      margin-bottom: 1em;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      color: #2999a9;
      width: 100%!important;
    }
    @include flexbox;
    color: #2999A9;
    h2{
      font-size: 1em;
      &:nth-child(1) {width: 30%;}
      &:nth-child(2) {width: 52%; @include phone { width: 66%; text-align: right; } }
      &:nth-child(3) {width: 18%;}
    }
  }
  &--footer {width: 100%; @include padbox(1em);
    @include flexbox; @include flow-rw; @include just-between; @include items-start;
    @include desk {padding: 0.5em 0; margin-bottom: 2em;}
    a:nth-of-type(1)  {width: 100%; margin-bottom: 1em; text-align: center; @include btn-back;
      background-color: #BBBBBB;
      border: none;
      color: white;
      @include radius(7px);
      @include tablet {width: 180px;}
      // &:first-of-type {@include btn-back;}
      // &:last-of-type {@include btn-go;}
    }
    a:first-of-type {
      @include phone { 
        width: 100%;
        margin-bottom: 1em;
        padding: 1em 1em;
        box-sizing: border-box;
        display: inline-block;
        background-color: #fff;
        color: #0097a9;
        border: 1px solid #0097a9;
        font-weight: 300;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        text-align: center;
        background-color: #bbb;
        border: none;
        color: #fff;
        -moz-border-radius: 7px;
        -webkit-border-radius: 7px;
        border-radius: 7px;
      }
    }
    button, a:nth-of-type(2) {width: 100%; margin-bottom: 1em; text-align: center; @include btn-go;
      @include phone { 
        width: 100%;
        margin-bottom: 1em;
        padding: 1em 1em;
        box-sizing: border-box;
        display: inline-block;
        background-color: #da291c;
        color: #fff;
        border: 1px solid #da291c;
        font-weight: 300;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        text-align: center;
        -moz-border-radius: 7px;
        -webkit-border-radius: 7px;
        border-radius: 7px;
      }
      @include tablet {width: 180px;}
      @include radius(7px);
    }
    div { display: block; width: 100%;
      .notification {
        @include padblock(0.5rem); background-color: #eee; color: $red; margin-bottom: 1rem; @include radius(9px);
      }
    }
  }
  .product--card { @include padbox(0.5rem); text-align: center;
    @include desk { @include transform(translateX(1.5rem)); }
  }
  .colorInformation{
    color: blue,
  }
  &--warning { @include flexbox; @include flow-rn; @include just-start; @include items-center;
    width: 100%; background-color: $sky; @include padbox(0.75rem); margin-bottom: 1rem; 
    img { width: 40px; display: inline-block; margin-right: 1rem; }
    p { display: inline-block; color: $dark; opacity: 0.8; text-align: justify;
      @include desk { font-size: 1.2rem; }
      b { display: inline; padding: 0 4px; font-weight: bold; }
    }
  }

  // summary
  &--summary {border: 1px solid $light;
    &-header {width: 100%; @include padbox(1em); background-color: $soft; border-bottom: 1px solid $light;
      @include flexbox; @include flow-rn; @include just-between; @include items-center;
      p {font-weight: 500; font-size: 1.25em;}
      a { @include padblock(0.5rem); font-size: 0.9rem; color: $blue; border: 1px solid $blue; @include radius(4px); }
    }
    &-detail {width: 100%; @include padbox(1em);
      @include flexbox; @include flow-rn; @include just-between; @include items-start;
      picture {width: 20%; text-align: center;}
      section {width: 78%;}
    }
    &-desc {width: 100%; margin-bottom: 1em;
      b {font-size: 0.7em; margin-bottom: 6px;}
      p {font-size: 1em;}
      span {font-size: 0.8em; color: $grey;}
    }
    &-sub {width: 100%; position: relative; margin-bottom: 1em;
      @include flexbox; @include flow-rn; @include just-between; @include items-start;
      b {font-size: 1em; font-weight: 300;}
      p {display: inline-block; font-size: 1.25em;
        .legend {position: absolute; right: 0; top: calc(100% + 0.15em); font-size: 60%;}
      }
    }
    &-total {width: 100%; @include padbox(1em); background-color: $soft; border-top: 1px solid $light;
      @include flexbox; @include flow-rn; @include just-between; @include items-start;
      b {font-size: 1.75em; font-weight: 300; color: $red;}
      p {font-size: 1.75em;}
    }
    &-user {width: 100%; @include padblock(1em 1em 0 1em); border-top: 1px solid $light;
      &-block {width: 100%; margin-bottom: 1em;
        header {width: 100%; font-size: 0.7em; margin-bottom: 6px;
          @include flexbox; @include flow-rn; @include just-between; @include items-center;
          // b {}
          a {color: $blue;
            &:hover {text-decoration: underline;}
          }
        }
        // > p {}
        > span {font-size: 0.8em; color: $grey;}
      }
    }
    &-address { width: 100%;
      section { width: 100%; @include padblock(1rem);
        header { @include flexbox; @include flow-rw; @include just-between; @include items-center; font-size: 0.75rem;
          p { font-weight: 500; margin-bottom: 5px; }
          a { color: $blue;
            &:hover { text-decoration: underline; }
          }
        }
        > p { font-size: 1rem; font-weight: 300; }
        b { font-size: 0.85rem; font-weight: 300; color: $gray; margin-top: 5px; }
      }
    }
  }

  // empty
  &--empty {width: 100%; text-align: center; min-height: 40vh;
    @include flexbox; @include flow-cn; @include just-center; @include items-center;
    p {margin-bottom: 1em;}
    a {@include padblock(0.5em); @include radius(3px); color: $blue; background-color: white; border: 1px solid $blue; width: 280px;
      span {
        &::before {content: "\f104"; font-family: $icon; display: inline-block; padding-right: 6px; @include trans;}
      }
      &:hover {
        span::before {@include transform(translateX(-6px));}
      }
    }
  }

  // detail
  &--content {width: 100%; @include padblock(1em); // margin-bottom: 1rem;
    @include desk {padding: 0;}
  }

  &--box {width: 100%; // border-bottom: 1px solid $light;
    @include flexbox; @include flow-rw; @include just-between; @include items-start;
  }

  &--image {text-align: center; width: 100%; margin-bottom: 1em; 
    @include flexbox; @include flow-cn; 
    // @include just-center; @include items-center;
    @include tablet {width: 30%;}
    h2 {font-size: 1em; font-weight: 400; padding-bottom: 0.5em; text-align: left;
      width: 100%; border-bottom: 1px solid $light;
    }
    &-link {
      picture { text-align: center; @include padbox(1em);
        img {display: inline-block; height: 90px; width: auto;}
      }
      &:hover { opacity: 0.5; }
    }
    
    a {font-size: 1.25em; color: $blue;
      padding: 0.5em;
      font-weight: 600;
      width: 70%;
      box-shadow: 3px 3px 10px #DCDCDC; 
      text-align: start;
      color: #666666;
      border-radius: 7px;
      @include phone { font-size: 1.25em; color: #0097a9; padding: .5em; font-weight: 600; width: 94%; box-shadow: 3px 3px 10px #dcdcdc; text-align: start; color: #666; border-radius: 7px; } 
      &:hover {text-decoration: underline;}
      &.delete {
        &::before { content: "\f00d"; font-family: $icon; display: inline-block; padding-right: 4px; }
      }
      &:last-of-type {
        text-align: center;
        color: $red;
        width: 72%;
        font-size: 0.75em;
        font-weight: 600;
        margin-top: 1.5em;
        @include phone { text-align: center; color: #da291c; width: 96%; font-size: .75em; font-weight: 600; margin-top: 1.5em; }
      }
    }
  }

  &--qty { background-color: white; border: 1px solid $light; color: $dark; @include padblock(0.5rem); text-align: center; margin-top: 0.5rem;
    p { font-size: 0.9rem; display: inline-block; padding-right: 0.5rem; border-right: 1px solid $light; }
    i { display: inline-block; padding-left: 9px; cursor: not-allowed; opacity: 0.5;
      &::before, &::after { font-family: $icon; font-style: normal; display: block; line-height: 0; font-size: 0.9rem; }
      &::before { content: "\f0de"; }
      &::after { content: "\f0dd"; }
    }
  }

  &--number {width: 100%; margin-top: 2em;
    b {font-size: 0.8em; margin-bottom: 0.8em;}
    p {font-size: 0.9em; font-weight: 400;
      span {
        svg {height: 1em; width: auto; display: inline-block; padding-right: 6px; vertical-align: text-top;}
      }
    }
  }

  &--head, &--detail {width: 100%; position: relative;
    header {width: 100%; margin-bottom: 1em;
      @include flexbox; @include flow-cn; @include just-start; @include items-start;
      @include tablet {width: 73%; margin-bottom: 0em;}
      h3 {font-size: 0.87em; font-weight: 500; color: #333333;}
      a, p {font-size: 0.875rem; margin: 10px 0 5px 0;
        & + p {margin-top: 0;}
        span {color: $grey;}
      }
      a:hover { color: $blue; }
      ul {width: 100%; margin-top: 1.15em;
        li {width: 100%; margin-bottom: 5px; color: $grey;
          &::before {content: "\· "; color: $red; font-size: 2em; vertical-align: middle; line-height: 0;}
          b {font-size: 0.85em; padding-right: 3px;}
          span {font-size: 0.85em;}
        }
      }
    }
    section {width: 50%;
      @include tablet {width: 27%;}
      p {font-size: 0.9em; text-align: left;
        @include tablet {@include padbox(1.5em 0 0 0);}
      }
      .price {font-size: 1.15em; text-align: left;
        color: $red;
        bottom: 0.5em;
        position: absolute;
        @include phone { font-size: 1.15em; text-align: left; color: #da291c; bottom: .5em; position: absolute;  right: 0; bottom: 26px; font-weight: bold; }
        .legend {font-size: 0.8em; width: 100%;}
      }
      // b {}
    }
  }

  &--head {padding-bottom: 0.5rem;
    @include flexbox; @include flow-rw; @include just-between; @include items-start; border-bottom: 1px solid $light;
    header {
      h2 {display: none;
        @include tablet {font-size: 1em; font-weight: 400; padding-bottom: 0.5em; text-align: left; width: 100%;
          border-bottom: 1px solid $light; margin-bottom: 1em; display: inline-block;
        }
      }
      h3{
        color: #2999A9;
        font-size: 0.75;
        @include desk { margin-top: 10px; }
      }
      p{
        color: #666666;
        font-size: 0.87em;
        font-weight: 300;
      }
    }
    &-p{
      color: #666666;
      font-size: 0.7em;
      font-weight: 100;
    }
    &-redes-sociales{
      @include flexbox;
    }
    section {
      h2 {font-size: 1em; font-weight: 400; padding-bottom: 0.5em; text-align: left; width: 100%; margin-bottom: 0;
        border-bottom: none;
        @include tablet {border-bottom: 1px solid $light; margin-bottom: 0.5em;}
      }
    }
  }

  &--detail {padding: 0.5rem 0;
    @include flexbox; @include flow-rw; @include just-between; @include items-start; border-bottom: 1px solid $light;
    &:last-of-type {border-bottom: none;}
    section {
      h2 {display: inline-block; font-size: 1em; font-weight: 400; padding-bottom: 0.5em; text-align: left;
        @include tablet {display: none;}
      }
    }
  }

  &--description {width: 100%; // border-bottom: 1px solid $light;
    @include flexbox; @include flow-rw; @include just-between; @include items-start;
    @include tablet {width: 70%;}
  }

  // &--sub {width: 100%; @include padbox(1em);
  //   @include flexbox; @include flow-rw; @include just-start; @include items-center;
  //   @include tablet {padding-left: 30%;}
  //   header {width: 100%; margin-bottom: 1em;
  //     @include tablet {width: 75%; margin-bottom: 0em;}
  //     h3 {font-size: 1em; font-weight: 400;}
  //   }
  //   section {width: 50%;
  //     @include tablet {width: 25%;}
  //     h2 {display: inline-block; font-size: 1em; font-weight: 400; padding-bottom: 0.5em; text-align: left;
  //       @include tablet {display: none;}
  //     }
  //     p {font-size: 1em; text-align: left;
  //       @include tablet {@include padbox(0em);}
  //     }
  //     .price {font-size: 1.15em; text-align: left;
  //       .legend {font-size: 0.8em; width: 100%;}
  //     }
  //     // b {}
  //   }
  // }

  &--total {width: 100%; @include padblock(0 1em 1em 1em);
    border-bottom: 1px solid #ddd;
    color: $red;
    @include phone { width: 100%; padding: 0 1em 1em; box-sizing: border-box; display: inline-block; border-bottom: 1px solid #ddd;  color: #da291c; font-weight: bold; }
    @include desk {padding: 0 0 1em 0;}
    section {width: 100%; @include padblock(1em);
      text-align: end;
      > p { 
        @include tablet { margin-right: 3.5rem; }
        @include desk { margin-right: 7.5rem; }
      }
      b, p {font-size: 2em; display: inline-block;}
      b {font-weight: 300; color: $red; padding-right: 6px;
        @include phone { font-weight: 300; color: #da291c; padding-right: 6px; float: left; font-weight: bold; }
      }
    }
  }

  &--limit-msg { opacity: 0.6; width: auto !important;
    span::after { display: none !important; }
  }

  &--modal {

    @mixin base--cart_modal {
      p { font-size: 1rem; margin-bottom: 1rem; text-align: left; }
      a, button { @include padblock(0.5rem); width: 44%; border: 1px solid $blue; color: $blue; text-align: center; margin-bottom: 0.5rem; background-color: white;
        span { display: inline-block;
          &::before { font-family: $icon; display: inline-block; padding-right: 6px; }
        }
        // &:hover { background-color: $blue; color: white; }
      }
    }

    &-one_time { @include base--cart_modal; text-align: center;
      a { margin: 1rem 0;
        span::before { content: "\f00d"; }
      }
    }

    &-switch_item { @include base--cart_modal;
      nav { width: 100%; @include flexbox; @include flow-rw; @include just-around; @include items-center; margin-bottom: 1rem;
        button {
          &:nth-of-type(1) {
            span::before { content: "\f088";  }
          }
          &:nth-of-type(2) {
            span::before { content: "\f087";  }
          }
        }
      }
      > span { color: $grey; font-size: 0.9rem; }
    }
  }

  .cross--box {
    a.add {color: white; background-color: $red; width: 100%;
      text-align: center; @include radius(10px);
      padding: 0;
      @include desk {width: 100%;}
      span {@include padblock(0.5em);
        &::after {content: "\f105"; font-family: $icon; display: inline-block; padding-left: 6px; vertical-align: baseline; @include trans;}
      }
      &:hover span::after {@include transform(translateX(6px));}
      &.custom { background-color: $blue; }
    }
  }

  // pospago
  // &-pospago {
  //
  //   &--content {width: 100%; @include padblock(1em);
  //     @include desk {padding: 0;}
  //   }
  //
  //   &--box {width: 100%; border-bottom: 1px solid $light;
  //     @include flexbox; @include flow-rw; @include just-between; @include items-start;
  //   }
  //
  //   &--image {text-align: center; width: 100%; margin-bottom: 2em;
  //     @include tablet {width: 30%;}
  //     h2 {font-size: 1em; font-weight: 400; padding-bottom: 0.5em; text-align: left;
  //       width: 100%; border-bottom: 1px solid $light;
  //     }
  //     picture {text-align: center; @include padbox(1em);
  //       img {display: inline-block; height: 180px; width: auto;}
  //     }
  //     a {font-size: 0.8em; color: $blue;
  //       &:hover {text-decoration: underline;}
  //     }
  //   }
  //
  //   &--number {width: 100%; margin-top: 2em;
  //     b {font-size: 0.8em; margin-bottom: 0.8em;}
  //     p {font-size: 0.9em; font-weight: 400;
  //       span {
  //         svg {height: 1em; width: auto; display: inline-block; padding-right: 6px; vertical-align: text-top;}
  //       }
  //     }
  //   }
  //
  //   &--head, &--detail {width: 100%; position: relative;
  //     header {width: 100%; margin-bottom: 1em;
  //       @include flexbox; @include flow-cn; @include just-start; @include items-start;
  //       @include tablet {width: 46%; margin-bottom: 0em;}
  //       h3 {font-size: 0.85em; font-weight: 500;}
  //       p {font-size: 1em; margin: 10px 0 5px 0;
  //         & + p {margin-top: 0;}
  //         span {color: $grey;}
  //       }
  //       ul {width: 100%; margin-top: 1.15em;
  //         li {width: 100%; margin-bottom: 5px; color: $grey;
  //           &::before {content: "\· "; color: $red; font-size: 2em; vertical-align: middle; line-height: 0;}
  //           b {font-size: 0.85em; padding-right: 3px;}
  //           span {font-size: 0.85em;}
  //         }
  //       }
  //     }
  //     section {width: 50%;
  //       @include tablet {width: 27%;}
  //       p {font-size: 0.9em; text-align: left;
  //         @include tablet {@include padbox(1.5em 0 0 0);}
  //       }
  //       .price {font-size: 1.15em; text-align: left;
  //         .legend {font-size: 0.8em; width: 100%;}
  //       }
  //       b {}
  //     }
  //   }
  //
  //   &--head {padding-bottom: 1em;
  //     @include flexbox; @include flow-rw; @include just-between; @include items-start; border-bottom: 1px solid $light;
  //     header {
  //       h2 {display: none;
  //         @include tablet {font-size: 1em; font-weight: 400; padding-bottom: 0.5em; text-align: left; width: 100%;
  //           border-bottom: 1px solid $light; margin-bottom: 1em; display: inline-block;
  //         }
  //       }
  //     }
  //     section {
  //       h2 {font-size: 1em; font-weight: 400; padding-bottom: 0.5em; text-align: left; width: 100%; margin-bottom: 0;
  //         border-bottom: none;
  //         @include tablet {border-bottom: 1px solid $light; margin-bottom: 0.5em;}
  //       }
  //     }
  //   }
  //
  //   &--detail {padding: 1em 0;
  //     @include flexbox; @include flow-rw; @include just-between; @include items-start; border-bottom: 1px solid $light;
  //     &:last-of-type {border-bottom: none;}
  //     section {
  //       h2 {display: inline-block; font-size: 1em; font-weight: 400; padding-bottom: 0.5em; text-align: left;
  //         @include tablet {display: none;}
  //       }
  //     }
  //   }
  //
  //   &--description {width: 100%; // border-bottom: 1px solid $light;
  //     @include flexbox; @include flow-rw; @include just-between; @include items-start;
  //     @include tablet {width: 70%;}
  //   }
  //
  //   &--sub {width: 100%; @include padbox(1em);
  //     @include flexbox; @include flow-rw; @include just-start; @include items-center;
  //     @include tablet {padding-left: 30%;}
  //     header {width: 100%; margin-bottom: 1em;
  //       @include tablet {width: 46%; margin-bottom: 0em;}
  //       h3 {font-size: 1.25em; font-weight: 400;}
  //     }
  //     section {width: 50%;
  //       h2 {display: inline-block; font-size: 1em; font-weight: 400; padding-bottom: 0.5em; text-align: left;
  //         @include tablet {display: none;}
  //       }
  //       @include tablet {width: 27%;}
  //       p {font-size: 1em; text-align: left;
  //         @include tablet {@include padbox(1.5em 0 0 0);}
  //       }
  //       .price {font-size: 1.15em; text-align: left;
  //         .legend {font-size: 0.8em; width: 100%;}
  //       }
  //       b {}
  //     }
  //   }
  //
  //   &--total {width: 100%; @include padblock(1em);
  //     @include desk {padding: 1em 0;}
  //     section {width: 100%; @include padblock(1em); background-color: $soft;
  //       box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3); text-align: right;
  //       b, p {font-size: 2em; display: inline-block;}
  //       b {font-weight: 300; color: $red; padding-right: 6px;}
  //     }
  //   }
  //
  // }

}
