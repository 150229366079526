.page {

  &--password {
    width: 90%; margin: 0 auto; padding: 1rem 0 0 0;
    @include tablet { width: 70%; padding: 2rem 0 0 0; }
    @include desk { width: 50%; }

    &-success {
      width: 100%; height: 100vh; position: fixed; @include full0; z-index: 99; background-color: rgba(black, 0.6);
      @include flexbox; @include flow-cn; @include just-center; @include items-center;
      visibility: hidden; opacity: 0; @include trans;
      &.active { visibility: visible; opacity: 1; @include trans; }
      section { display: inline-block; width: 80%; text-align: center; padding: 2rem 1rem; background-color: white; @include radius(3px);
        @include tablet { width: 70%; }
        @include desk { width: 50%; }
        p { font-size: 1.35rem; }
        b { margin: 0.5rem 0 1rem 0; display: block; font-weight: 300; }
        a {width: auto; @include padbox(1em); @include radius(3px);
          background-color: $red; color: white; text-align: center; margin: 1em 0;
        }
      }
    }
  }

  &--purchase_limit { width: 100%; @include module; @include padbox(1rem);
    @include flexbox; @include flow-rw; @include just-start; @include items-start;
    @include desk { padding: 0; }
    aside { width: 100%; text-align: center;
      @include tablet { width: auto; }
      i { display: inline-block; width: auto; margin-bottom: 2rem;
        @include desk { margin: 0 1rem 2rem 0; }
        svg { width: 130px; display: inline-block; }
      }
    }
    section { width: 100%;
      @include tablet { width: auto; }
      h2 { font-size: 1.5rem; font-weight: 300; margin-bottom: 1rem;
        @include tablet { font-size: 1.85rem; }
      }
      p { font-size: 0.85rem; margin: 1rem 0;
        @include tablet { font-size: 1rem; }
      }
      a { @include btn-back; margin: 1rem 0; }
    }
  }

  &--activation {
    width: 100%;
    @include module;
    text-align: center;
    @include padbox(2rem 1rem);
    @include tablet {
      padding: 3rem 1rem;
    }
    h2 {
      width: 100%;
      margin: 1rem 0;
      font-size: 2rem;
    }
    h3 {
      display: inline-block;
      font-size: 1.25rem;
      font-weight: 400;
      @include padbox(0 1rem 0.5rem 1rem);
      border-bottom: 1px solid $dark;
      margin-bottom: 1rem;
    }
    div {
      width: 100%;
      margin: 1rem 0;
      @include flexbox;
      @include flow-cn;
      @include just-center;
      @include items-stretch;
      text-align: center;
      @include tablet {
        @include flow-rn;
        @include just-start;
        @include items-center;
        text-align: left;
      }
      span {
        font-size: 2.4rem;
        color: $red;
        @include tablet {
          margin-right: 1rem;
        }
      }
      p {
        display: inline-block;
        @include tablet {
          border-left: 1px solid $light;
          padding: 0.5rem 1rem;
        }
        a {
          color: $blue;
          font-weight: 500;
          &:hover { text-decoration: underline; }
        }
      }
    }
  }

  &--error {
    width: 100%;
    @include padbox(0 1rem);
    @include flexbox; @include flow-rw; @include just-between; @include items-center;
    @include desk { padding: 0 50px; }
    > div { width: 100%; text-align: left; margin: 2rem 0;
      span { display: inline-block;
        svg { width: 130px; height: auto;
          * { fill: $red; }
        }
      }
    }
    section {
      width: 100%;
      @include tablet { width: 60%; }
      div {
        b { font-size: 5rem; font-weight: 400; }
        p { display: inline-block; margin-left: 15px; font-size: 1.2rem; }
      }
      h2 { font-size: 1.7rem; font-weight: 400; margin: 1rem 0; }
      a { margin: 1rem 0; @include padblock(1rem 2rem); background-color: $red; color: white; border-radius: 4px; }
    }
    aside { display: none;
      @include tablet { display: inline-block; width: 30%; }
      img { display: block; }
    }
    &-404 {
      section {
        margin: 1rem;
        p {
          font-size: 1.3rem;
          font-weight: 500;
          margin-bottom: 1rem;
          text-align: center;
          @include tablet {
            text-align: left;
          }
        }
      }
      aside {
        @include tablet {
          order: 1;
        }
        > img {
          width: 70vw;
          margin: auto;
          @include tablet {
            width: 100%;
            max-width: 500px;
          }
        }
      }
      .error {
        &-content {
          display: flex;
          flex-direction: column;
          @include tablet {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-height: calc(100vh - 60px);
          }
        }
        &-header {
          background-color: $red;
          height: 60px;
          > span {
            width: 100px;
            padding: .5rem 1rem;
          }
        }
        &-title {
          color: $red;
          font-weight: bold;
          text-align: center;
          font-size: 2rem;
          margin-bottom: 1rem;
          @include tablet {
            text-align: left;
            font-size: 3rem;
          }
        }
        &-subtitle {
          text-align: center;
          font-size: 1.5rem;
          margin-bottom: 1rem;
          @include tablet {
            text-align: left;
          }
        }
        &-categories {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 1rem;
          margin-bottom: 2rem;
          @include tablet {
            max-width: 360px;
          }
          .category {
            display: grid;
            border-radius: 10px;
            box-shadow: 0 4px 10px #dcdcdc;
            text-align: center;
            font-weight: bold;
            font-size: .8rem;
            a {
              padding: .5rem;
              display: grid;
              @include tablet {
                max-width: 120px;
              }
              img {
                width: 32px;
                height: 32px;
                margin: 5px auto;
              }
            }
          }
        }
        &-link-back {
          display: block;
          text-align: center;
          color: $blue;
          font-weight: bold;
          border: 1px solid $blue;
          border-radius: 5px;
          padding: .75rem;
          @include tablet {
            max-width: 300px;
          }
        }
      }
    }
  }

}
