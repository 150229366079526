.catalog {
  width: 100%;
  @include padbox(0 1.25em);
  position: relative; // @include module; // margin-bottom: 100px;
  @include tablet {
    padding: 0 0.75em;
  }
  @include desk {
    padding: 0;
  } // for quiz + margin-bottom: 51px;

  &--header {
    width: 100%;
    padding: 1em 0;
    @include module;
    @include flexbox;
    @include flow-rw;
    @include just-between;
    @include items-center;
    h2 {
      font-weight: 300;
      font-size: 2em;
      @include phone {
        font-weight: 300;
        font-size: 2em;
        padding-left: 19px;
      }
    }
    // p {font-size: 0.9em; display: inline-block;
    //   span {display: inline;}
    //   a {display: inline; color: $blue;
    //     &::after {content: "\f105"; font-family: $icon; display: inline-block; padding-left: 6px;}
    //     &:hover {text-decoration: underline;}
    //   }
    // }
  }

  &--module {
    @include flexbox;
    @include flow-rw;
    @include just-between;
    @include items-start;
    @include module;
    margin-bottom: 2rem;
    @include phone {
      width: 88%;
    }
  }

  &--banner {
    width: 100%;
    margin-bottom: 1em;
    .slick {
      &-track {
        width: 100%;
        @include flexbox;
        @include flow-rn;
        @include just-start;
        @include items-center;
        img {
          @include radius(15px);
        }
      }
      &-slide {
        width: 100%;
      }
      &-arrow {
        position: absolute;
        top: 40%;
        z-index: 1; //top: 30%;
        svg {
          @include size(32px, 32px);
          path:first-child {
            fill: #bbbbbb;
            @include trans;
          }
          path:last-child {
            fill: white;
          }
        }
        &:hover {
          svg {
            path:first-child {
              fill: #dddddd;
            }
          }
        }
      }
      &-prev {
        left: 0.5em;
      }
      &-next {
        right: 0.5em;
      }
      &-dots {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0.5em;
        @include flexbox_i;
        @include flow-rw;
        @include just-center;
        @include items-center;
        li {
          display: inline-block;
          font-size: 0.8em;
          margin: 0 1em;
          opacity: 0.5;
          @include trans;
          &.slick-active {
            opacity: 1;
            @include transform(scale(1.5));
          }
          a {
            color: white;
          }
        }
      }
    }
  }

  &--grid {
    width: 100%;
    @include tablet {
      @include flexbox;
      @include flow-rw;
      @include just-between;
      @include items-start;
    }
  }

  &--grid-productos {
    margin: 1em auto;
    display: grid;
    @include phone {
      grid-template-columns: 1fr;
    }
    @include tablet {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include desk {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    grid-auto-rows: auto;
    grid-gap: 1.5em;
    article {
      width: 100%;
    }
  }

  &--grid-productos-detalle {
    margin: 1em auto;
    display: grid;
    @include phone {
      grid-template-columns: 1fr;
    }
    @include tablet {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include desk {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    grid-auto-rows: auto;
    grid-gap: 1.5em;
    article {
      width: 100%;
    }
  }
  &--grid-detalle {
    @include phone {
      width: 100%;
    }
    @include tablet {
      width: 70%;
    }
    @include desk {
      width: 70%;
    }
  }

  &--product-section {
    width: 100%;
    margin-bottom: 1em;
    h1 {
      color: #333333;
      font-size: 1.5em;
      @include phone {
        margin-bottom: 1em;
      }
    }
  }
  &--seemore-container {
    text-align: center;
    @include phone {
      margin-bottom: 35px;
    }
  }

  &--seemore {
    width: 40%;
    text-align: center;
    padding: 0.5em;
    background: $blue;
    border-radius: 5px;
    padding-right: 5em;
    padding-left: 5em;
    color: white;
    @include phone {
      width: 90%;
      text-align: center;
      padding: 0.5em;
      background: #0097a9;
      border-radius: 5px;
      padding-right: 5em;
      padding-left: 5em;
      color: #fff;
      height: 51px;
      font-weight: bold;
    }
  }
  &--filters {
    width: 100%;
    margin-bottom: 1em;
    @include tablet {
      width: 30%;
    }
    @include desk {
      width: 22%;
    }
    @include phone {
      width: 100%;
      margin-bottom: 1em;
      margin-top: 16px;
    }
    input[type="radio"] {
      -webkit-appearance: radio;
      -moz-appearance: radio;
      appearance: radio;
    }
    input[type="checkbox"] {
      -webkit-appearance: checkbox;
      -moz-appearance: checkbox;
      appearance: checkbox;
    }

    &-trigger {
      width: 100%;
      @include padblock(0.5em 1em);
      border: 1px solid $light;
      position: relative;
      span {
        width: 100%;
        &::before {
          content: "Filtros";
        }
        &::after {
          content: "\f0d7";
          font-family: $icon;
          position: absolute;
          top: 0.5em;
          right: 1em;
        }
      }
      &.active {
        span {
          &::before {
            content: "Ocultar Filtros";
          }
          &::after {
            content: "\f0d8";
          }
        }
      }
      @include tablet {
        display: none;
      }
    }

    header {
      width: 100%;
      @include padblock(1em 0);
      @include tablet {
        @include padbox(1em);
        background-color: $soft;
      }
      h5 {
        font-size: 1em;
        font-weight: 300;
        display: block;
        margin-bottom: 1.25em;
      }
      label {
        width: auto;
        margin-right: 1.25em;
        color: $grey;
        @include tablet {
          width: 100%;
          margin-bottom: 0.5em;
        }
        input {
          display: inline-block;
          width: auto;
          margin-right: 9px;
        }
        h6 {
          font-size: 0.9em;
          font-weight: 300;
          display: inline-block;
        }
      }
    }

    &-list {
      width: 100%;
      @include padbox(0 1em);
      background-color: $snow;
      border: 1px solid $light;
      border-top: none;
      display: none;
      @include tablet {
        border: none;
        display: inline-block !important;
      }
      aside {
        width: 100%;
        @include padbox(1em 0);
        border-bottom: 1px solid $light;
        @include flexbox;
        @include flow-rw;
        @include just-between;
        @include items-center;
        //@include tablet {border-top: 1px solid $light;}
        h5 {
          font-size: 0.98em;
          font-weight: 300;
        }
        a {
          color: $blue;
          font-size: 0.8em;
        }
      }
      section {
        width: 100%;
        color: $grey;
        @include padblock(1em 0);
        border-bottom: 1px solid $light;
        &:last-of-type {
          border-bottom: none;
        }
        h3 {
          width: 100%;
          font-size: 0.95em;
          font-weight: 300;
          display: inline-block;
          position: relative;
          cursor: pointer;
          color: $black;
          &:not(.filter--price)::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            font-size: 4em;
            color: $black;
            line-height: 0.3;
            overflow: hidden;
            height: 14px;
            width: 14px;
            border-right: 2px solid $black;
            border-top: 2px solid $black;
            transform: rotate(-45deg);
          }
          &.collapsed::after {
            transform: rotate(135deg);
          }
        }
        > div {
          width: 100%;
          display: block;
          margin-top: 1.25em;
          label {
            width: 100%;
            margin-bottom: 0.5em;
            input {
              display: inline-block;
              width: auto;
              margin-right: 9px;
            }
            h6 {
              font-size: 0.85em;
              font-weight: 300;
              display: inline-block;
              vertical-align: text-top;
            }
          }
        }
        .colors {
          label {
            width: auto;
            margin-right: 6px;
            @include radius(99px);
            text-align: center;
            line-height: 2;
            i {
              display: inline-block;
              @include size(22px, 22px);
              @include radius(99px);
              line-height: 1.9;
              border: 1px solid $soft;
              @include trans;
              @include phone {
                line-height: 1.6;
              }
              span {
                @include radius(99px);
                @include size(18px, 18px);
                @include inset;
              }
            }
            input {
              display: none;
              &:checked + i {
                border-color: $blue;
              }
            }
            &:hover {
              i {
                border-color: $blue;
              }
            }
            &.disabled i:hover {
              border-color: transparent;
            }
          }
        }
      }
    }
  }

  &--quiz {
    width: 100%;
    background-color: #444;
    display: inline-block;
    z-index: 1; // position: static;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    &.fixed {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: auto;
    }
    section {
      width: 100%;
      color: white;
      @include padbox(0.5em);
      position: relative;
      text-align: center;
      @include module;
      @include flexbox;
      @include flow-rw;
      @include just-between;
      @include items-center;
      h6 {
        font-size: 1.1em;
        font-weight: 300;
        padding-bottom: 0.5em;
        width: 100%;
        @include desk {
          width: auto;
          padding-bottom: 0;
        }
      }
      p {
        font-size: 0.8em;
        font-weight: 400;
        padding-bottom: 0.5em;
        width: 100%;
        @include desk {
          width: auto;
          padding-bottom: 0;
          font-size: 0.9em;
          &::before {
            content: "\f111";
            font-family: $icon;
            display: inline-block;
            padding-right: 2em;
            color: $blue;
          }
        }
      }
      div {
        width: 100%;
        text-align: center;
        @include desk {
          width: auto;
        }
        a {
          @include padbox(0.5em);
          background-color: $blue;
          color: white;
          width: 180px;
          @include radius(3px);
          text-align: center;
          span {
            &::after {
              content: "\f105";
              font-family: $icon;
              display: inline-block;
              padding-left: 6px;
              @include trans;
            }
          }
          &:hover span::after {
            @include transform(translateX(6px));
          }
        }
      }
      i {
        cursor: pointer;
        position: absolute;
        top: 0.25em;
        right: 0.25em;
        font-size: 1.5em;
        @include desk {
          position: static;
          font-size: 1em;
          top: 0.5em;
          right: 0.5em;
        }
      }
    }
  }

  .header--breadcrumb nav {
    a,
    span {
      padding-right: 6px;
      font-size: 0.9em;
      text-transform: capitalize;
      cursor: pointer;
      &::after {
        content: "/";
        display: inline-block;
        padding-left: 6px;
      }
    }
  }
}

.catalog--module {
  max-width: 1200px;
}

/*MENU*/
.catalog--header {
  .header--breadcrumb {
    nav {
      a {
        color: #bbb;
        font-weight: 400;
      }
      span {
        color: #da291c;
        font-weight: 400;
      }
    }
  }
}
.nav--grid {
  position: relative;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  box-shadow: 0 7px 13px -8px gray;
  border-radius: 0 0 12px 12px;
  margin: 30px auto;
  .nav--category-box {
    border-radius: 0 !important;
    padding: 5px 1em;
    margin-left: 0;
    padding-left: 8px !important;
    .active {
      border-radius: 0 !important;
      border-bottom: 4px solid #da291c !important;
    }
  }
}
.nav--grid2 {
  position: relative;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  box-shadow: 0 7px 13px -8px gray;
  border-radius: 0 0 12px 12px;
  margin: 30px auto;
  .nav--category-box {
    border-radius: 0 !important;
    padding: 5px 1em;
    margin-left: 0;
    padding-left: 8px !important;
    .active {
      border-radius: 0 !important;
      border-bottom: 4px solid #da291c !important;
    }
  }
}

/*CATALOGO GRID BODY*/
.catalog--grid {
  max-width: 1060px;
  margin: 0 auto;
  .headProduct {
    display: flex;
    justify-content: space-between;
    @include tablet {
        .nav--search.category {
          margin-left: 3rem;
        }
    }
  }
  .nav--search {
    width: auto;
    text-align: right;
    .search--box {
      position: relative;
      text-align: left;
      width: auto;
      &-results {
        left: 0;
      }
      .ButtonSearch {
        display: block;
        border: none;
        background-color: transparent;
        color: #444;
      }
      button {
        display: none;
      }
      label {
        text-align: right;
        #search-box {
          margin: 0;
          width: 0;
          padding: 0;
          height: 40px;
          border-radius: 12px;
          transition: all 0.5s;
          border-color: #fff;
          &::placeholder {
            display: flex;
            justify-content: space-between;
          }
        }
      }
      .showBar {
        #search-box {
          width: 280px;
          padding: 7px;
          transition: all 0.5s;
          border-color: #afafaf;
        }
      }
    }
  }
}

/*FILTRO*/
.catalog--filters {
  box-shadow: 0 0 13px -8px gray;
  width: 25% !important;
  .catalog--filters-list {
    background-color: #fff;
    aside {
      border: none;
      display: grid !important;
      h5 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 20px;
        grid-column: 1 / 3;
      }
      span {
        font-size: 12px;
        font-weight: 500;
      }
      a {
        text-decoration: underline !important;
      }
    }
    section {
      border: none;
      &.filter--tipo-plan {
        grid-column: 1 / 3;
        padding-top: 0;
        > div {
            margin-top: 0;
        }
      }
      h3 {
        font-size: 18px;
        color: #444;
        font-weight: bold;
        &::after {
          height: 8px;
          width: 8px;
        }
      }
      > div:not(.colors) {
        padding-left: 20px;
        label {
          position: relative;
          padding-left: 35px;
          max-width: calc(100% - 35px);
          span {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #d1d0d0;
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
          }
          input {
            display: none;
          }
          input:checked + span {
            background-color: #da291c;
          }
        }
      }
    }
  }
  .btn-apply,
  .btn-cancel {
    display: none;
  }
  @include phone {
    .btn-apply {
      display: block;
      background-color: $red;
      width: 100%;
      color: white;
      border-radius: 10px;
      padding: 8px;
      font-size: .9rem;
      font-weight: bold;
    }
    .btn-cancel {
      text-align: center;
      margin: 1rem auto;
      display: block;
      color: $grey;
      font-size: .9rem;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.nav--search {
  background-color: #fff;
}

.menuResponsive {
  width: 100%;
  button {
    display: none;
  }
}

.buttonResponsive {
  display: none !important;
}
@media (min-width: 761px) {
  .buttonDesktop {
    display: block !important;
  }
  .nav--grid {
    display: flex !important;
  }
  .nav--grid2 {
    display: flex !important;
  }
}
@media (max-width: 760px) {
  .catalog {
    padding-top: 40px;
    .ui--login-btn {
      margin-top: 40px;
    }
  }
  .buttonDesktop {
    display: none !important;
  }
  .buttonResponsive {
    display: flex !important;
    align-items: center;
  }
  .catalog--header {
    display: none;
  }
  .menuResponsive {
    display: block;
    position: relative;
    width: 90%;
    height: 3rem;
    button {
      display: flex;
      align-items: center;
      height: 100%;
      color: $black;
      span {
        position: relative;
        &::after {
          height: 8px;
          width: 8px;
          transform: rotate(135deg);
          content: "";
          position: absolute;
          top: 4px;
          right: -20px;
          font-size: 4em;
          color: #222222;
          line-height: 0.3;
          overflow: hidden;
          border-right: 2px solid #222222;
          border-top: 2px solid #222222;
        }
      }
    }
    img {
      height: 32px;
      width: 32px;
      display: inline;
    }
  }
  .nav--grid {
    display: none;
    position: absolute;
    top: 50px;
    background-color: #fff;
    z-index: 3;
    margin: 0;
    padding: 20px;
    flex-wrap: nowrap;
    flex-direction: column;
    // width: calc(100% - 10px);
    margin-left: 4px;
    width: calc(100% - -3px);
    .nav--category {
      display: flex;
      align-items: center;
      span {
        color: #000;
        margin: 0 !important;
        min-width: auto;
      }
    }
    .active {
      border: none;
    }
  }
  .full {
    padding: 0;
  }
  .nav--grid2 {
    display: none;
    position: absolute;
    top: 50px;
    background-color: #fff;
    z-index: 3;
    margin: 0;
    padding: 20px;
    flex-wrap: nowrap;
    flex-direction: column;
    .nav--category {
      display: flex;
      align-items: center;
      span {
        color: #000;
        margin: 0 !important;
        min-width: auto;
      }
    }
    .active {
      border: none;
    }
  }
  .catalog--filters {
    position: absolute;
    width: auto !important;
    box-shadow: none;
    top: 59px;
    right: 7%;
    margin: 0;
    .active {
      width: 90% !important;
      left: 10%;
    }
    .catalog--filters-trigger {
      display: flex;
      padding: 0;
      border: none;
      gap: 10px;
      justify-content: right;
      span {
        font-size: 14px;
        width: auto;
        &::after {
          display: none;
        }
      }
    }
    .catalog--filters-list {
      width: 90%;
      margin: 0 0 0 10%;
      z-index: 3;
      background-color: #fff;
      position: relative;
    }
  }
  .headProduct {
    flex-direction: row !important;
  }
  .catalog--grid {
    padding-top: 40px;
    .nav--search {
      position: absolute;
      margin: 0;
      top: -7px;
      min-width: 60px;
      .search--box {
        width: 99% !important;
        button {
          z-index: 1059;
        }
        .showBar {
          #search-box {
            width: 100%;
            position: relative;
            z-index: 9;
            background-color: #fff;
          }
        }
      }
    }
  }
}


.floatingMenu {

  position: absolute;
  width: 100%;
  box-shadow: 2px 7px 13px -4px rgb(108, 108, 108);
  box-shadow: 0 1px 8px rgba(0,0,0,.17);
  border-radius: 8px;
  top: 50px;
  width: 200px;
  overflow: hidden;
  z-index: 2;
  // padding: 12px 0;
  background: white;
  animation: showSlowlyFloatingMeni 0.5s ease-in-out;
  margin-top: 16px;


}

.floatingMenuarrow {
  border-color: transparent;
  border-style: solid;
  display: block;
  height: 12px;
  position: absolute;
  width: 12px;
  box-shadow: 3px -1px 9px -3px #6c6c6c;
  position: absolute;
  top: -9px;
  background: white;
  transform: rotate(-45deg);
  left: calc(100% - 20px);
  z-index: -16;
  position: absolute;
  top: 58px;
  overflow: hidden;
  z-index: 2;
  // padding: 12px 0;
  background: white;
  animation: showSlowlyFloatingMeni 0.5s ease-in-out;
}

.floatingMenu__item {
  padding: 12px 12px;
  border-bottom: 1px solid #eaeaea;
  font-size: 14px;background-color: white;
  font-weight: 400;
  color: #666666;
}

.floatingMenu__item:hover {
  background-color: #f4f1f1;
  cursor: pointer;
}

.floatingMenu__item:last-child {
  border-bottom: none;
}

@keyframes showSlowlyFloatingMeni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
